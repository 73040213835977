/**
 * Points
 *
 * @returns {void}
 */
const points = () => {
  const points = $('.car__point')
  const wrapper = $('.car__points')

  // const titlesWrapper = $('.expo__titles')
  // const titlesItems = $('.expo__titles-item')

  let timerId, timerIds = []
  const duration = 3000
  const timeout = duration * points.length

  // $(points).on('click', function () {
  //   let index = $(this).index();
  //
  //   titlesItems.removeClass('is-active')
  //   titlesItems.eq(index).addClass('is-active')
  // });

  /**
   * @private
   */
  const _eachPoints = () => {
    points.each((index, element) => {

      timerIds[index] = setTimeout(() => {

        points.removeClass('is-active')
        $(element).addClass('is-active')

        // titlesItems.removeClass('is-active')
        // titlesItems.eq(index).addClass('is-active')

      }, index * duration)

    })
  }

  /**
   * @private
   */
  const _startAnimation = () => {
    _eachPoints()

    timerId = setInterval(_eachPoints, timeout)
  }

  /**
   * @private
   */
  const _endAnimation = () => {
    clearInterval(timerId)

    timerIds.forEach((timer) => {
      clearTimeout(timer)
    })

    points.removeClass('is-active')
    // titlesItems.removeClass('is-active')

    timerId = null
    timerIds = []
  }

  _startAnimation()

  points.on('mouseover', _endAnimation).on('mouseout', _endAnimation)
  wrapper.on('mouseover', _endAnimation).on('mouseout', _startAnimation)
}

export default points
