// import * as jQuery from 'jquery';

// import hamburgerMenu from './components/menu';
// import './libs/magnificPopup/jquery.magnific-popup.min';
// import './libs/owlCarousel/owl.carousel';
// import makePopup from './components/make-popup';

import points from './components/points'

// window.$ = jQuery.default;

((html) => {
    html.className = html.className.replace(/\bno-js\b/, 'js');
})(document.documentElement);

$(() => {

  points();

  const calcForm = $('#calculator');
  if(calcForm.length) {
    const oneCar = 14721;
    const monthIncome = 385.5;
    let casco = 0;
    let residualValues = {
      year1 : 12000,
      year2 : 11000,
      year3 : 10000,
      year4 : 9000,
      year5 : 8000
    };

    let cars, period, result, resultForYearPercent, yearPercent, incomePlusCar;

    $('.calculator__form').on('change input', function () {
        let data = calcForm.serializeArray();

        $.each(data, function() {
          if(this.name == 'cars') {
            cars = this.value;
            $('.calculator__cars-number span').html(cars);
            $('.calculator__invest span').html(String(cars*oneCar).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 '));
            $('.calculator__month-income-value span').html(String(Math.round(cars*monthIncome)).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 '));
          }

          if(this.name == 'period') {
            period = this.value;
          }

          if(this.name == 'casco') {
            casco = 1250;
          } else {
            casco = 0;
          }
        });

        result = ((monthIncome*12*period)-(casco*period))*cars;
        incomePlusCar = residualValues['year'+period]*cars + result;
        result = incomePlusCar - cars*oneCar;
        result = Math.round(result);
        $('.calculator__income-value').html(String(result).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 '));

        resultForYearPercent = (monthIncome*12*period)-(casco*period);
        yearPercent = Math.round((((resultForYearPercent+residualValues['year'+period])/oneCar-1)/period)*100);
        $('.calculator__percent-value span').html(yearPercent);
    });

    $('.calculator__cars .form-control').on('input', function () {
      let max = this.max;
      let val = ((this.value - this.min) / (this.max - this.min))*100;

      $(this).css('background-image',
        'linear-gradient(90deg, rgba(0, 62, 155, 1) '+ val +'%, rgba(224, 224, 224, 0.7)'+ val +'%)'
      );
    });
  }

  if ($(window).width() <= 980) {
    $('.t228__mobile .t228__mobile_container .t228__mobile_text').html('<a href="/"><img src="https://utp-tilda.dudka.agency/dist/img/logo-mobile-new.svg" class="t228__imglogo" alt="Logo"></a>')
  }


    // makePopup('.js-slider-popup', 'inline');

    // slider
    // $('.js-reviews-slider').owlCarousel({
    //     loop: true,
    //     margin: 85,
    //     nav: true,
    //     dots: true,
    //     items: 2,
    //     responsive: {
    //         300 : {
    //             margin: 30,
    //             items: 1
    //         },
    //         767 : {
    //             margin: 30,
    //             items: 2,
    //         },
    //         1000 : {
    //             margin: 50
    //         },
    //         1199 : {
    //             margin: 85
    //         }
    //     }
    // });


    // scroll to selected section
    // $('#t-header a.t-menu__link-item, #nav263934597 a.t-menu__link-item').on('click',function (e) {
    //     let headerHeight;
    //
    //     if($('#t-header').length) {
    //         headerHeight = $('#t-header').outerHeight();
    //     }
    //
    //     if($('#nav263934597').length) {
    //         headerHeight = $('#nav263934597').outerHeight();
    //     }
    //
    //     if(this.hash) {
    //         e.preventDefault();
    //     }
    //
    //     let target = this.hash,
    //         $target = $(target);
    //
    //     $('html, body').stop().animate({
    //         'scrollTop': $target.offset().top - headerHeight
    //     }, 800, 'swing');
    // });

    // for analytics
    // let $formBtn = $('.t-form .t-form__submit .t-submit');
    // let $headerBtn = $('#nav263934597 a[href^="#popup:"]');
    // let $headerBtnRus = $('#nav263931498 a[href^="#popup:"]');
    // let $tel = $('#nav263934597 a[href^="tel:"]');
    // let $telRus = $('#nav263931498 a[href^="tel:"]');
    // let $footerBtn = $('#rec258875823 a[href^="#popup:"]');
    // let $footerBtnRus = $('#rec224418493 a[href^="#popup:"]');
    // let $clientsBtn = $('#rec258875818 a[href^="#popup:"]');
    // let $clientsBtnRus = $('#rec247272237 a[href^="#popup:"]');
    //
    // function setAnalyticsAttrs(elem, action, value) {
    //     elem.attr('data-analytics-action', action);
    //     elem.attr('data-analytics-value', value);
    //     elem.addClass('analytics-elem');
    // }
    //
    // setTimeout(function () {
    //     if($formBtn.length) {
    //         setAnalyticsAttrs($formBtn, 'submit','form-btn' );
    //     }
    //     if($headerBtn.length) {
    //         setAnalyticsAttrs($headerBtn, 'click','header-btn' );
    //     }
    //     if($headerBtnRus.length) {
    //         setAnalyticsAttrs($headerBtnRus, 'click','header-btn' );
    //     }
    //     if($tel.length) {
    //         setAnalyticsAttrs($tel, 'click','tel' );
    //     }
    //     if($telRus.length) {
    //         setAnalyticsAttrs($telRus, 'click','tel' );
    //     }
    //     if($footerBtn.length) {
    //         setAnalyticsAttrs($footerBtn, 'click','footer-btn' );
    //     }
    //     if($footerBtnRus.length) {
    //         setAnalyticsAttrs($footerBtnRus, 'click','footer-btn' );
    //     }
    //     if($clientsBtn.length) {
    //         setAnalyticsAttrs($clientsBtn, 'click','clients-btn' );
    //     }
    //     if($clientsBtnRus.length) {
    //         setAnalyticsAttrs($clientsBtnRus, 'click','clients-btn' );
    //     }
    // },1000 );
    //
    // $(document).on('click', '.analytics-elem', function (e) {
    //     let analytics_action = $(this).data('analytics-action');
    //     let analytics_value = $(this).data('analytics-value');
    //
    //     if(typeof dataLayer !== 'undefined' && analytics_action) {
    //         console.log('gtag: ' + analytics_action + ' - ' + analytics_value);
    //
    //         //Click Button - analytics-follow-us
    //         //dataLayer.push({'event': 'CtaButtons', 'analytics_action': analytics_action, 'analytics_value': analytics_value});
    //         gtag('event', analytics_action, {'event_category': 'CtaButtons', 'event_label': analytics_value});
    //     }
    // });
});
